import axios from "axios"

const Axios = axios.create({
  baseURL: process.env.GATSBY_SAE_API_URL,
  headers: {
    "api-key": process.env.GATSBY_SAE_API_KEY,
  },
})

class Buzon {
  idTipo: number
  idPromovente: number
  expresion: string
  idArea: number
  personalImplicado: string

  constructor(buzon?: Partial<Buzon>) {
    this.idTipo = buzon?.idTipo || 0;
    this.idPromovente = buzon?.idPromovente || 0;
    this.expresion = buzon?.expresion || "";
    this.idArea = buzon?.idArea || 0;
    this.personalImplicado = buzon?.personalImplicado || "";
  }

  create = async (): Promise<CreateResponse> => {
    const response = await Axios.post("/buzon", this)
    return response.data
  }
}

interface CreateResponse extends Buzon {
  idBuzon: number
}

export default Buzon
