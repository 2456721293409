import axios from "axios"

const Axios = axios.create({
  baseURL: process.env.GATSBY_SAE_API_URL,
  headers: {
    "api-key": process.env.GATSBY_SAE_API_KEY,
  },
})

class TipoBuzon {
  idTipo: number
  nombre: string

  constructor(tipo: TipoBuzon) {
    this.idTipo = tipo.idTipo
    this.nombre = tipo.nombre
  }

  public static getAll = async () => {
    const res = await Axios.get("/tipos-buzon")
    return res.data.map((tipo: TipoBuzon) => new TipoBuzon(tipo))
  }
}

export default TipoBuzon
