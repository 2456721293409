import axios from "axios"
import Direccion from "./Direccion"

const Axios = axios.create({
  baseURL: process.env.GATSBY_SAE_API_URL,
  headers: {
    "api-key": process.env.GATSBY_SAE_API_KEY,
  },
})

class AreaIsset {
  idArea: number
  idDireccion: number
  nombre: string
  borrado: boolean
  direccion: Direccion

  constructor(area: AreaIsset) {
    this.idArea = area.idArea
    this.idDireccion = area.idDireccion
    this.nombre = area.nombre
    this.borrado = area.borrado
    this.direccion = area.direccion
  }

  public static getAll = async (
    params?: Partial<IGetAllParams>
  ): Promise<AreaIsset[]> => {
    const res = await Axios.get("/areas-isset", {
      params,
    })
    return res.data.map((area: AreaIsset) => new AreaIsset(area))
  }
}

interface IGetAllParams {
  idDireccion: number
}

export default AreaIsset
