import axios from "axios"

const Axios = axios.create({
  baseURL: process.env.GATSBY_SAE_API_URL,
  headers: {
    "api-key": process.env.GATSBY_SAE_API_KEY,
  },
})

class TipoPromovente {
  idTipoPromovente: number
  nombre: string

  constructor(tipoPromovente: TipoPromovente) {
    this.idTipoPromovente = tipoPromovente.idTipoPromovente
    this.nombre = tipoPromovente.nombre
  }

  static getAllTipoPromovente = async(): Promise<TipoPromovente[]> => {
    const resp = await Axios.get('/tipos-promovente')
    return resp.data.map( (tipo: TipoPromovente) => new TipoPromovente(tipo) )
  }
}

export default TipoPromovente
