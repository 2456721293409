import axios from "axios"

const Axios = axios.create({
  baseURL: process.env.GATSBY_SAE_API_URL,
  headers: {
    "api-key": process.env.GATSBY_SAE_API_KEY,
  },
})

class Direccion {
  idDireccion: number
  nombre: string
  borrado: boolean

  constructor(direccion: Direccion) {
    this.idDireccion = direccion.idDireccion
    this.nombre = direccion.nombre
    this.borrado = direccion.borrado
  }

  public static getAll = async (): Promise<Direccion[]> => {
    const res = await Axios.get("/direcciones-isset")
    return res.data.map((direccion: Direccion) => new Direccion(direccion))
  }
}

export default Direccion
