import axios from "axios"

const Axios = axios.create({
  baseURL: process.env.GATSBY_SAE_API_URL,
  headers: {
    "api-key": process.env.GATSBY_SAE_API_KEY,
  }
})

class Estado {
  EstadoId: number
  ClaveEstado: number
  Descripcion: string
  Abreviacion: string

  constructor(estado: Estado) {
    this.EstadoId = estado.EstadoId
    this.ClaveEstado = estado.ClaveEstado
    this.Descripcion = estado.Descripcion
    this.Abreviacion = estado.Abreviacion
  }

  static getAllEstados = async (): Promise<Estado[]> => {
    const res = await Axios.get("/estados")
    return res.data.map((estado: Estado) => new Estado(estado))
  }
}

export default Estado
