import axios from 'axios';

const Axios = axios.create({
  baseURL: process.env.GATSBY_SAE_API_URL,
  headers: {
    "api-key": process.env.GATSBY_SAE_API_KEY,
  },
})


class Genero {
  idGenero: number
  nombre: string

  constructor(genero: Genero){
    this.nombre = genero.nombre
    this.idGenero = genero.idGenero
  }


  static getAllGenero = async(): Promise<Genero[]> => {
    const resp = await Axios.get('/generos')
    return resp.data.map( (genero: Genero) => new Genero(genero) )
  }

}

export default Genero
