import React, { useEffect, useState } from "react"
import {
  LinearProgress,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@material-ui/core"
import { ChevronLeft } from "@material-ui/icons"
import IssetLogo from "../../assets/images/logo/logo-isset-negro.png"
import { Link } from "gatsby"
import "../../assets/styles/suggestions-mailbox.scss"
import PersonalDataStep from "./PersonalDataStep"
import Promovente from "../../classes/Promovente"
import TipoPromovente from "../../classes/TipoPromovente"
import Genero from "../../classes/Genero"
import Estado from "../../classes/Estado"
import SuggestionStep from "./SuggestionStep"
import Direccion from "../../classes/Direccion"
import Buzon from "../../classes/Buzon"
import AreaIsset from "../../classes/AreaIsset"
import TipoBuzon from "../../classes/TipoBuzon"
import Swal from "sweetalert2"
import { AxiosError } from "axios"

const SuggestionsMailbox = () => {
  const [activeStep, setActiveStep] = useState(0)
  const [promovente, setPromovente] = useState(new Promovente())
  const [buzon, setBuzon] = useState(new Buzon())
  const [tiposPromovente, setTiposPromovente] = useState<TipoPromovente[]>([])
  const [generos, setGeneros] = useState<Genero[]>([])
  const [estados, setEstados] = useState<Estado[]>([])
  const [direcciones, setDirecciones] = useState<Direccion[]>([])
  const [areas, setAreas] = useState<AreaIsset[]>([])
  const [tiposBuzon, setTiposBuzon] = useState<TipoBuzon[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const initialize = async () => {
      setLoading(true)
      setTiposPromovente(await TipoPromovente.getAllTipoPromovente())
      setGeneros(await Genero.getAllGenero())
      setEstados(await Estado.getAllEstados())
      setDirecciones(await Direccion.getAll())
      setAreas(await AreaIsset.getAll())
      setTiposBuzon(await TipoBuzon.getAll())
      setLoading(false)
    }

    initialize()
  }, [])

  const handleSend = async () => {
    try {
      let idPromovente = 0
      if (promovente.idPromovente) {
        // Promovente already exists
        idPromovente = promovente.idPromovente
      } else {
        // We have to create the promovente in the database
        const createdPromovente = await new Promovente(promovente).create()
        setPromovente({
          ...promovente,
          idPromovente: createdPromovente.idPromovente,
        })
        idPromovente = createdPromovente.idPromovente
      }
      await new Buzon({ ...buzon, idPromovente }).create()

      Swal.fire(
        "Hoja de buzón enviada",
        "Su hoja de buzón fue enviada y será atendida por el personal correspondiente a la información proporcionada. Muchas gracias por usar los servicios del Instituto de Seguridad Social del Estado de Tabasco.",
        "success"
      ).then(()=>{
        window.location.reload()
      })

    } catch (err) {
      const error = err as AxiosError
      Swal.fire("Error", error.response.data.errors[0].msg)
    }
  }

  if (loading) return <LinearProgress />
  return (
    <div className="sug-mail">
      <Paper className="sug-mail__content">
        <Link to="/">
          <ChevronLeft style={{ color: "black" }} />
          <img src={IssetLogo} alt="ISSET" className="sug-mail__logo" />
        </Link>

        <div className="sug-mail__content__info">
          <Typography variant="h4" component="h1">
            Buzón de sugerencias
          </Typography>
          <Typography color="textSecondary" variant="body2">
            La opinión de nuestros derechohabientes nos ayuda en la mejora de
            nuestros servicios, y la atención brindada por el personal. Aquí,
            podrás expresar dudas o inconformidades que hayan surgido en las
            instalaciones del ISSET.
          </Typography>
        </div>

        <Stepper activeStep={activeStep}>
          <Step>
            <StepLabel>Datos personales</StepLabel>
          </Step>
          <Step>
            <StepLabel>Sugerencia</StepLabel>
          </Step>
        </Stepper>

        {activeStep === 0 && (
          <PersonalDataStep
            estados={estados}
            generos={generos}
            promovente={promovente}
            setPromovente={setPromovente}
            tiposPromovente={tiposPromovente}
            nextStep={() => setActiveStep(activeStep + 1)}
          />
        )}
        {activeStep === 1 && (
          <SuggestionStep
            tipos={tiposBuzon}
            direcciones={direcciones}
            areas={areas}
            buzon={buzon}
            setBuzon={setBuzon}
            previousStep={() => setActiveStep(activeStep - 1)}
            send={handleSend}
          />
        )}
      </Paper>

      <footer>
        Gobierno del estado de Tabasco | Todos los derechos reservados -{" "}
        {new Date().getFullYear()}
      </footer>
    </div>
  )
}

export default SuggestionsMailbox
